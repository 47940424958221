var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('b-row',{staticClass:"m-1 box-shadow2 p-4 dashHead",staticStyle:{"border-radius":"20px"}},[_c('b-col',[_c('b-row',{staticClass:"mt-1",staticStyle:{"justify-content":"space-between"}},[_c('b-col',{attrs:{"xs":"12","sm":"12","md":"4","lg":"3","xl":"2"}},[_c('h1',{staticClass:"display-3 font-weight-bold"},[_vm._v(_vm._s(_vm.dashboardDetails.overallCompletion ? _vm.dashboardDetails.overallCompletion : 0)+"%")])]),_c('b-col',{attrs:{"xs":"12","sm":"12","md":"8","lg":"9","xl":"10"}},[_c('h2',[_vm._v(" "+_vm._s(_vm.dashboardDetails.heading ? _vm.dashboardDetails.heading : ''))]),_c('b-row',{staticClass:"mt-4",staticStyle:{"flex-wrap":"wrap","row-gap":"30px"}},_vm._l((_vm.dashboardDetails.regionDetails),function(region){return _c('b-col',{key:region.id,staticStyle:{"font-size":"larger"}},[_vm._v(" "+_vm._s(_vm.trimText(region.name ? region.name : ''))+" "),_c('strong',[_vm._v(_vm._s(region.completion ? region.completion : 0)+"%")])])}),1)],1)],1),_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('span',{staticStyle:{"font-size":"larger"}},[_vm._v("100%")]),_c('b-row',{staticStyle:{"height":"25vh","align-items":"flex-end","border-top":"1px dashed grey","flex-wrap":"nowrap","overflow-x":"auto","gap":"5px"}},_vm._l((_vm.dashboardDetails.score),function(item,index){return _c('b-col',{key:item.id,staticStyle:{"text-align":"center","flex":"1","padding":"0"}},[_c('div',{class:{
                bar: !_vm.isDarkMode,
                'bar bg-secondary': _vm.isDarkMode
              },style:({
                height: (item.completion / 100) * 21.5 + 'vh',
                borderRadius: '5px',
                backgroundColor: item.completion < 90 ? '#888' : '#E88A3E'
              })}),_c('span',{staticStyle:{"font-size":"larger"}},[_vm._v(" "+_vm._s(index + 1)+" ")])])}),1)],1)],1),_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('b-row',{staticClass:"m-1 bordered"},[_c('b-col',_vm._l((_vm.dashboardDetails.checklists),function(item,index){return _c('div',{key:index,staticClass:"mb-1"},[_c('b-card',{class:{
                  'accordion-card': !_vm.isDarkMode,
                  'accordion-card bg-secondary text-light': _vm.isDarkMode
                },attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"accordion-header",attrs:{"header-tag":"header","role":"tab"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(_vm.getCollapseId(item.checklist, item.appendText)),expression:"getCollapseId(item.checklist, item.appendText)"}],staticClass:"accordion-toggle",class:{
                      'accordion-toggle': !_vm.isDarkMode,
                      'accordion-toggle bg-secondary text-light': _vm.isDarkMode
                    },attrs:{"variant":"light"}},[_c('span',{staticClass:"checklist-title"},[_vm._v(_vm._s(item.checklist))]),_c('span',{staticClass:"completion-percentage"},[_vm._v(_vm._s(item.compliance ? item.compliance : 0)+"%")]),_c('span',{staticClass:"chevron-icon"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'chevron-down']}})],1)])],1),(item.regions && item.regions.length)?_c('b-collapse',{class:{
                    '': !_vm.isDarkMode,
                    'bg-dark text-light': _vm.isDarkMode
                  },attrs:{"id":_vm.getCollapseId(item.checklist, item.appendText),"accordion":"my-accordion"}},_vm._l((item.regions),function(subItem,subIndex){return _c('b-card',{key:subIndex,class:{
                      '': !_vm.isDarkMode,
                      'bg-dark border text-light': _vm.isDarkMode
                    },staticStyle:{"padding":"0","line-height":"0"}},[_c('b-card-body',{staticStyle:{"padding":"0"}},[_c('p',[_vm._v(_vm._s(subItem.region ? subItem.region : '')+": "+_vm._s(subItem.compliance ? subItem.compliance : 0)+"%")])])],1)}),1):_vm._e()],1)],1)}),0)],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }