<template>
  <div style="min-height: 80vh" class="mx-5">
    <div
      style="position: sticky; top: 6vh; z-index: 1000"
      :class="{
        'bg-light': !isDarkMode,
        'bg-dark text-light ': isDarkMode
      }"
    >
      <h1
        :class="{
          'bg-light text-center page-title ': !isDarkMode,
          'bg-dark text-light text-center page-title ': isDarkMode
        }"
        class=""
        >{{ title }}</h1
      >
      <b-row
        style="justify-content: space-between"
        :class="{
          'bg-light mx-2  py-3': !isDarkMode,
          ' mx-2  py-3 bg-dark text-light': isDarkMode
        }"
        ><b-col xl="5">
          <b-row>
            <b-col>
              <b-form-select
                aria-placeholder="select"
                v-model="filterSelected"
                :options="filters"
                :class="{
                  'box-shadow2 mb-2': !isDarkMode,
                  'box-shadow2 bg-dark text-light mb-2': isDarkMode
                }"
              ></b-form-select>
            </b-col>
            <b-col>
              <b-form-select
                v-model="renderedSelected"
                :options="renderedList"
                :class="{
                  'box-shadow2  mb-2': !isDarkMode,
                  'box-shadow2 bg-dark text-light  mb-2': isDarkMode
                }"
              ></b-form-select>
            </b-col>
          </b-row>
        </b-col>
        <b-col xl="5">
          <b-row>
            <b-col>
              <b-form data-v-step="1">
                <b-input-group class="box-shadow2 mb-2">
                  <b-input-group-append>
                    <b-form-datepicker
                      sm
                      button-only
                      id="start-datepicker"
                      placeholder="Start Date"
                      :min="minDate"
                      :max="maxDate"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      v-model="startDateValue"
                    >
                    </b-form-datepicker>
                  </b-input-group-append>
                  <b-form-input
                    id="example-input"
                    v-model="startDateValue"
                    type="text"
                    placeholder="Start Date"
                    autocomplete="off"
                    :class="{
                      '': !isDarkMode,
                      'bg-dark text-light': isDarkMode
                    }"
                    disabled
                  ></b-form-input>
                </b-input-group> </b-form
            ></b-col>
            <b-col>
              <b-form>
                <b-input-group class="box-shadow2 mb-2">
                  <b-input-group-append>
                    <b-form-datepicker
                      button-only
                      id="end-datepicker"
                      placeholder="End Date"
                      :min="minDate"
                      :max="maxDate"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      v-model="endDateValue"
                    ></b-form-datepicker>
                  </b-input-group-append>
                  <b-form-input
                    id="example-input"
                    v-model="endDateValue"
                    type="text"
                    placeholder="End Date"
                    autocomplete="off"
                    :class="{
                      '': !isDarkMode,
                      'bg-dark text-light ': isDarkMode
                    }"
                    disabled
                  ></b-form-input>
                </b-input-group> </b-form
            ></b-col>
            <b-col>
              <b-form>
                <b-button
                  data-v-step="2"
                  :class="{
                    'box-shadow2 btn btn-success  mb-2': !isDarkMode,
                    'btn btn-primary text-light  mb-2': isDarkMode
                  }"
                  @click="showChart"
                  ><font-awesome-icon class="mr-1" :icon="['fas', 'arrow-alt-circle-right']"
                /></b-button> </b-form
            ></b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>

    <b-overlay :show="loading" :variant="!isDarkMode ? 'light' : 'dark'" rounded="sm" no-wrap>
      <template #overlay>
        <div class="d-flex justify-content-center align-items-center h-100">
          <b-spinner :variant="!isDarkMode ? 'dark' : 'light'" label="Loading"></b-spinner>
        </div>
      </template>
    </b-overlay>
    <b-row
      :class="{
        ' mt-4 mx-2': !isDarkMode,
        'mt-4 bg-dark text-light mx-2': isDarkMode
      }"
    >
      <b-col>
        <DashboardSection :loading="loading" :dashboardDetails="completionStatus" />
      </b-col>
    </b-row>
    <b-row
      :class="{
        'mt-4 mx-2': !isDarkMode,
        'mt-4 bg-dark text-light mx-2': isDarkMode
      }"
    >
      <b-col>
        <DashboardSection :loading="loading" :dashboardDetails="completionScore" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import router from '@/router';
import { mapState, mapActions, mapGetters } from 'vuex';
import DashboardSection from '../../components/DashboardSection.vue';
import { LoggedInUser } from '../../helper/dashboard.js';

export default {
  name: 'Dashboard2',
  metaInfo() {
    return {
      title: 'Dashboard',
      meta: [
        {
          name: 'description',
          content: `See Charts`
        }
      ]
    };
  },
  components: { DashboardSection },
  data() {
    return {
      title: 'Checklist - Dashboard',
      renderedList: [{ text: 'select', value: 'select' }],
      renderedSelected: 'select',
      filterSelected: 'select',
      startDateValue: moment().format('YYYY-MM-DD'),
      endDateValue: moment().format('YYYY-MM-DD'),
      minDate: moment().subtract(3, 'month').format('YYYY-MM-DD'),
      maxDate: moment().format('YYYY-MM-DD'),
      completionStatus: {
        heading: 'Checklist Completion Status',
        overallCompletion: 0,
        regionDetails: [],
        checklists: []
      },
      completionScore: {
        heading: 'Checklist Completion Score',
        overallCompletion: 0,
        regionDetails: [],
        checklists: []
      }
    };
  },
  async mounted() {
    if (this.ifPettyCashTeam) {
      router.push('/petty-cash');
    } else {
      this.getStoreByUser();
      this.listStore({ router });
      this.asmListAction({ router });
      this.listRegion({ router, query: { type: 'region' } });
      this.list({ type: 'user', query: this.$route.query });
      this.mobileList({
        router,
        query: {
          startdate: moment(this.startDateValue).format('YYYY-MM-DD'),
          enddate: moment(this.endDateValue).format('YYYY-MM-DD')
        }
      });
    }
  },
  methods: {
    ...mapActions('asmList', ['asmListAction']),
    ...mapActions('dashboard', ['mobileList']),
    ...mapActions('store', ['listStore', 'listRegion']),
    ...mapActions('cstSubmission', ['getStoreByUser']),
    ...mapActions('user', ['list']),
    showChart() {
      const startDate = moment(this.startDateValue);
      const endDate = moment(this.endDateValue);
      const difference = endDate.diff(startDate, 'days');
      if (difference >= 0 && difference <= 30) {
        this.mobileList({
          router,
          query: {
            startdate: moment(this.startDateValue).format('YYYY-MM-DD'),
            enddate: moment(this.endDateValue).format('YYYY-MM-DD'),
            ...(this.filterSelected === 'region' ? { region: this.renderedSelected } : {}),
            ...(this.filterSelected === 'asm' ? { asm: this.renderedSelected } : {}),
            ...(this.filterSelected === 'store' ? { storeid: this.renderedSelected } : {})
          }
        });
      } else {
        Vue.swal({
          title: 'Proceed Validation',
          text: `Days limit upto 30`,
          type: 'error',
          background: this.isDarkMode ? '#464d54' : '#ffffff',
          customClass: {
            title: this.isDarkMode ? 'text-light' : 'text-dark',
            content: this.isDarkMode ? 'text-light' : 'text-dark'
          }
        });
      }
    }
  },
  computed: {
    ...mapState('darkMode', ['isDarkMode']),
    ...mapState('auth', ['user']),
    ...mapState('cstSubmission', ['store']),
    ...mapState('asmList', ['asmList']),
    ...mapState('dashboard', ['loading', 'dashboardData']),
    ...mapState('store', ['storeList', 'regionList']),
    ...mapState('user', ['users']),
    ...mapGetters('pettyCash', ['ifPettyCashTeam']),
    isAdmin() {
      return this.user.roles.some(role => role.name === 'ADMIN');
    },
    isSubAdmin() {
      return this.user.roles.some(role => role.name === 'SUB_ADMIN');
    },
    isCEO() {
      return this.user.roles.some(role => role.name === 'CEO');
    },
    isRSM() {
      return this.user.roles.some(role => role.name === 'REGIONAL_SALES_MANAGER');
    },
    isASM() {
      return this.user.roles.some(role => role.name === 'AREA_SALES_MANAGER');
    },
    isSSM() {
      return this.user.roles.some(role => role.name === 'STORE_SALES_MANAGER');
    },
    loginnedUserRegions() {
      return this.users.filter(i => i.mobile === this.user.mobile).map(item => item.regions);
    },
    totalStoreList() {
      return this.storeList
        .map(item => ({ text: `${item.storeName}-${item.storeid}`, value: item.storeid }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },
    loginnedUserStores() {
      return this.store
        .map(item => ({ text: `${item.storeName}-${item.storeid}`, value: item.storeid }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },
    renderedASMList() {
      return this.asmList
        .map(item => ({ text: item.value, value: item.key }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },
    filters() {
      if (this.isRSM) {
        return [
          { text: 'select', value: 'select' },
          { text: 'ASM ', value: 'asm' },
          { text: 'Store', value: 'store' }
        ];
      }
      if (this.isASM || this.isSSM) {
        return [
          { text: 'select', value: 'select' },
          { text: 'Store', value: 'store' }
        ];
      }
      if (this.isAdmin || this.isSubAdmin || this.isCEO) {
        return [
          { text: 'select', value: 'select' },
          { text: 'India', value: 'india' },
          { text: 'Region', value: 'region' },
          { text: 'ASM', value: 'asm' },
          { text: 'Store', value: 'store' }
        ];
      }
      return [
        { text: 'select', value: 'select' },
        { text: 'Store', value: 'store' }
      ];
    }
  },

  watch: {
    store(newValue) {
      if (newValue.length > 0) {
        const abc = LoggedInUser(this.user, this.store);
        this.mobileList({
          router,
          query: {
            startdate: moment(this.startDateValue).format('YYYY-MM-DD'),
            enddate: moment(this.endDateValue).format('YYYY-MM-DD'),
            ...(abc.userType === 'ASM' ? { asm: this.user.id } : {}),
            ...(abc.userType === 'RSM' ? { region: newValue[0]?.region } : {}),
            ...(abc.userType === 'SM' ? { storeid: abc.selectedSubGeographyType.value } : {})
          }
        });
      }
    },
    filterSelected(newValue) {
      if (newValue) {
        if (newValue === 'region') {
          this.renderedList = [...this.regionList.sort((a, b) => a.text.localeCompare(b.text))];
          this.renderedSelected = this.regionList[0].value;
          // if (this.isRSM) {
          //   this.renderedList = [...this.loginnedUserRegions[0]?.map(i => ({ text: i, value: i }))];
          //   this.renderedSelected = 'select';
          // } else {
          // }
        } else if (newValue === 'store') {
          if (this.isAdmin) {
            this.renderedList = [...this.totalStoreList];
            this.renderedSelected = this.totalStoreList[0].value;
          } else {
            this.renderedList = [...this.loginnedUserStores];
            this.renderedSelected = this.loginnedUserStores[0].value;
          }
        } else if (newValue === 'asm') {
          this.renderedList = [...this.renderedASMList];
          this.renderedSelected = this.renderedASMList[0].value;
        } else if (newValue === 'india') {
          this.renderedSelected = 'india';
          this.renderedList = [{ text: 'india', value: 'india' }];
        } else {
          this.renderedSelected = 'select';
          this.renderedList = [{ text: 'select', value: 'select' }];
          const abc = LoggedInUser(this.user, this.store);
          this.mobileList({
            router,
            query: {
              startdate: moment(this.startDateValue).format('YYYY-MM-DD'),
              enddate: moment(this.endDateValue).format('YYYY-MM-DD'),
              ...(abc.userType === 'ASM' ? { asm: this.user.id } : {}),
              ...(abc.userType === 'RSM' ? { region: this.store[0].region } : {}),
              ...(abc.userType === 'SM' ? { storeid: abc.selectedSubGeographyType.value } : {})
            }
          });
        }
      }
    },
    renderedSelected(newValue) {
      if (newValue && newValue !== 'select') {
        if (this.filterSelected === 'region') {
          this.mobileList({
            router,
            query: {
              startdate: moment(this.startDateValue).format('YYYY-MM-DD'),
              enddate: moment(this.endDateValue).format('YYYY-MM-DD'),
              region: newValue
            }
          });
        } else if (this.filterSelected === 'store') {
          this.mobileList({
            router,
            query: {
              startdate: moment(this.startDateValue).format('YYYY-MM-DD'),
              enddate: moment(this.endDateValue).format('YYYY-MM-DD'),
              storeid: newValue
            }
          });
        } else if (this.filterSelected === 'asm') {
          this.mobileList({
            router,
            query: {
              startdate: moment(this.startDateValue).format('YYYY-MM-DD'),
              enddate: moment(this.endDateValue).format('YYYY-MM-DD'),
              asm: newValue
            }
          });
        } else {
          this.mobileList({
            router,
            query: {
              startdate: moment(this.startDateValue).format('YYYY-MM-DD'),
              enddate: moment(this.endDateValue).format('YYYY-MM-DD')
            }
          });
        }
      }
    },
    dashboardData(newValue) {
      if (newValue) {
        const updatedStatusRegionDetails = Object.keys(newValue.complianceScoreByRegion ?? {})
          .map((item, index) => ({
            id: index + 1,
            name: item,
            completion: Math.round(newValue.complianceScoreByRegion[item] * 100)
          }))
          .sort((a, b) => a.name.localeCompare(b.name));
        const updatedScoreRegionDetails = Object.keys(newValue.checklistScoreByRegion ?? {})
          .map((item, index) => ({
            id: index + 1,
            name: item,
            completion: Math.round(newValue.checklistScoreByRegion[item] * 100)
          }))
          .sort((a, b) => a.name.localeCompare(b.name));
        const updatedScoreChecklist = newValue.actualChecklistScoreByRegion
          ? (newValue.actualChecklistScoreByRegion ?? [])
              .map(item => ({
                checklist: item.checklist ?? '',
                appendText: 'score',
                compliance: item.checklistScore !== null ? Math.round(item.checklistScore * 100) : 0,
                ...(this.filterSelected !== 'store' && !this.isSSM
                  ? {
                      regions:
                        this.isAdmin || this.isCEO || this.isSubAdmin
                          ? this.filterSelected === 'region' && typeof item.checklistScoreByASM === 'object'
                            ? Object.entries(item.checklistScoreByASM ?? {}).map(([region, value]) => ({
                                region,
                                compliance: value !== null ? Math.round(value * 100) : 0
                              }))
                            : this.filterSelected === 'asm' && typeof item.checklistScoreByStore === 'object'
                            ? Object.entries(item.checklistScoreByStore ?? {}).map(([region, value]) => ({
                                region,
                                compliance: value !== null ? Math.round(value * 100) : 0
                              }))
                            : typeof item.checklistScoreByRegion === 'object'
                            ? Object.entries(item.checklistScoreByRegion ?? {}).map(([region, value]) => ({
                                region,
                                compliance: value !== null ? Math.round(value * 100) : 0
                              }))
                            : []
                          : this.isRSM
                          ? this.filterSelected === 'asm' && typeof item.checklistScoreByStore === 'object'
                            ? Object.entries(item.checklistScoreByStore ?? {}).map(([region, value]) => ({
                                region,
                                compliance: value !== null ? Math.round(value * 100) : 0
                              }))
                            : Object.entries(item.checklistScoreByASM ?? {}).map(([region, value]) => ({
                                region,
                                compliance: value !== null ? Math.round(value * 100) : 0
                              }))
                          : this.isASM && typeof item.checklistScoreByStore === 'object'
                          ? Object.entries(item.checklistScoreByStore ?? {}).map(([region, value]) => ({
                              region,
                              compliance: value !== null ? Math.round(value * 100) : 0
                            }))
                          : []
                    }
                  : {})
              }))
              .sort((a, b) => a.checklist.localeCompare(b.checklist))
          : newValue.storeChecklistScoreData
          ? (newValue.storeChecklistScoreData ?? [])
              .map(item => ({
                checklist: item.checklist ?? '', // Fallback to empty string
                compliance: item.checklistCompliance !== null ? Math.round(item.checklistCompliance * 100) : 0
              }))
              .sort((a, b) => a.checklist.localeCompare(b.checklist))
          : null;

        const updatedStatusChecklist = newValue.actualChecklistData
          ? (newValue.actualChecklistData ?? [])
              .map(item => ({
                checklist: item.checklist ?? '', // Fallback to empty string
                appendText: 'status',
                compliance: item.checklistCompliance !== null ? Math.round(item.checklistCompliance * 100) : 0,
                ...(this.filterSelected !== 'store' || !this.isSSM
                  ? {
                      regions:
                        this.isAdmin || this.isCEO || this.isSubAdmin
                          ? this.filterSelected === 'region' && typeof item.checklistComplianceByASM === 'object'
                            ? Object.entries(item.checklistComplianceByASM ?? {}).map(([region, value]) => ({
                                region,
                                compliance: value !== null ? Math.round(value * 100) : 0
                              }))
                            : this.filterSelected === 'asm' && typeof item.checklistComplianceByStore === 'object'
                            ? Object.entries(item.checklistComplianceByStore ?? {}).map(([region, value]) => ({
                                region,
                                compliance: value !== null ? Math.round(value * 100) : 0
                              }))
                            : typeof item.checklistComplianceByRegion === 'object'
                            ? Object.entries(item.checklistComplianceByRegion ?? {}).map(([region, value]) => ({
                                region,
                                compliance: value !== null ? Math.round(value * 100) : 0
                              }))
                            : []
                          : this.isRSM
                          ? this.filterSelected === 'asm' && typeof item.checklistComplianceByStore === 'object'
                            ? Object.entries(item.checklistComplianceByStore ?? {}).map(([region, value]) => ({
                                region,
                                compliance: value !== null ? Math.round(value * 100) : 0
                              }))
                            : Object.entries(item.checklistComplianceByASM ?? {}).map(([region, value]) => ({
                                region,
                                compliance: value !== null ? Math.round(value * 100) : 0
                              }))
                          : this.isASM && typeof item.checklistComplianceByStore === 'object'
                          ? Object.entries(item.checklistComplianceByStore ?? {}).map(([region, value]) => ({
                              region,
                              compliance: value !== null ? Math.round(value * 100) : 0
                            }))
                          : []
                    }
                  : {})
              }))
              .sort((a, b) => a.checklist.localeCompare(b.checklist))
          : newValue.storeChecklistComplianceData
          ? (newValue.storeChecklistComplianceData ?? [])
              .map(item => ({
                checklist: item.checklist ?? '', // Fallback to empty string
                compliance: item.checklistCompliance !== null ? Math.round(item.checklistCompliance * 100) : 0
              }))
              .sort((a, b) => a.checklist.localeCompare(b.checklist))
          : null;

        const statusByDate = Array.from({ length: 30 }, (_, i) => {
          const dayNumber = i + 1;
          const matchingEntry = Object.entries(newValue.complianceScoreByDate ?? {}).find(([date]) => {
            return new Date(date).getDate() === dayNumber;
          });

          const value = matchingEntry ? matchingEntry[1] : null;

          return {
            id: i + 1,
            date: dayNumber,
            completion: value !== null ? Math.max(Math.round(value * 100), 10) : 0
          };
        });

        const scoreByDate = Array.from({ length: 30 }, (_, i) => {
          const dayNumber = i + 1;
          const matchingEntry = Object.entries(newValue.checklistScoreByDate ?? {}).find(([date]) => {
            return new Date(date).getDate() === dayNumber;
          });

          const value = matchingEntry ? matchingEntry[1] : null;
          return {
            id: i + 1,
            date: dayNumber,
            completion: value !== null ? Math.max(Math.round(value * 100), 10) : 0
          };
        });

        this.completionStatus = {
          ...this.completionStatus,
          overallCompletion: Math.floor(newValue.complianceScoreIndia * 100),
          regionDetails: updatedStatusRegionDetails,
          checklists: updatedStatusChecklist,
          score: statusByDate
        };
        this.completionScore = {
          ...this.completionScore,
          overallCompletion: Math.floor(newValue.checklistScoreIndia * 100),
          regionDetails: updatedScoreRegionDetails,
          checklists: updatedScoreChecklist,
          score: scoreByDate
        };
      }
    }
  }
};
</script>
