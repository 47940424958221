export const LoggedInUser = (user, allStores) => {
  const roles = user?.roles;
  if (roles && roles.length > 0) {
    if (
      roles.find(
        ({ _id }) =>
          _id.toString() === '60ec021c8df0eaf541a6a83b' ||
          _id.toString() === '63cff88c8ae24b74411b5208' ||
          _id.toString() === '64c0c2211533f289c937ec79' ||
          _id.toString() === '67d044bf08b85336c06559fa'
      )
    ) {
      return {
        userType: 'ADMIN',
        selectedGeographyType: 'India',
        selectedSubGeographyType: undefined
      };
    } else if (roles.find(({ _id }) => _id.toString() === '611948f4d5b0f56b01e03bbe')) {
      return {
        userType: 'RSM',
        selectedGeographyType: 'Region',
        selectedSubGeographyType: { value: allStores[0]?.region }
      };
    } else if (roles.find(({ _id }) => _id.toString() === '6118cdf369039874f9d4b33d')) {
      return {
        userType: 'ASM',
        selectedGeographyType: 'All Stores',
        selectedSubGeographyType: { value: '' }
      };
    } else {
      return {
        userType: 'SM',
        selectedGeographyType: 'Store',
        selectedSubGeographyType: { value: allStores[0]?.storeid }
      };
    }
  }
};
