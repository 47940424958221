<template>
  <b-row v-if="!loading" style="border-radius: 20px" class="m-1 box-shadow2 p-4 dashHead">
    <b-col>
      <b-row class="mt-1" style="justify-content: space-between">
        <b-col xs="12" sm="12" md="4" lg="3" xl="2">
          <h1 class="display-3 font-weight-bold"
            >{{ dashboardDetails.overallCompletion ? dashboardDetails.overallCompletion : 0 }}%</h1
          >
        </b-col>
        <b-col xs="12" sm="12" md="8" lg="9" xl="10">
          <h2> {{ dashboardDetails.heading ? dashboardDetails.heading : '' }}</h2>
          <b-row style="flex-wrap: wrap; row-gap: 30px" class="mt-4">
            <b-col style="font-size: larger" v-for="region in dashboardDetails.regionDetails" :key="region.id">
              {{ trimText(region.name ? region.name : '') }}
              <strong>{{ region.completion ? region.completion : 0 }}%</strong>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <!-- Chart -->
      <b-row class="mt-3">
        <b-col>
          <span style="font-size: larger">100%</span>
          <b-row
            style="
              height: 25vh;
              align-items: flex-end;
              border-top: 1px dashed grey;
              flex-wrap: nowrap;
              overflow-x: auto;
              gap: 5px;
            "
          >
            <b-col
              v-for="(item, index) in dashboardDetails.score"
              :key="item.id"
              style="text-align: center; flex: 1; padding: 0"
            >
              <div
                :class="{
                  bar: !isDarkMode,
                  'bar bg-secondary': isDarkMode
                }"
                :style="{
                  height: (item.completion / 100) * 21.5 + 'vh',
                  borderRadius: '5px',
                  backgroundColor: item.completion < 90 ? '#888' : '#E88A3E'
                }"
              ></div>
              <span style="font-size: larger">
                {{ index + 1 }}
              </span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col>
          <b-row class="m-1 bordered">
            <b-col>
              <div class="mb-1" v-for="(item, index) in dashboardDetails.checklists" :key="index">
                <b-card
                  no-body
                  :class="{
                    'accordion-card': !isDarkMode,
                    'accordion-card bg-secondary text-light': isDarkMode
                  }"
                >
                  <b-card-header header-tag="header" role="tab" class="accordion-header">
                    <b-button
                      :class="{
                        'accordion-toggle': !isDarkMode,
                        'accordion-toggle bg-secondary text-light': isDarkMode
                      }"
                      v-b-toggle="getCollapseId(item.checklist, item.appendText)"
                      variant="light"
                      class="accordion-toggle"
                    >
                      <span class="checklist-title">{{ item.checklist }}</span>
                      <span class="completion-percentage">{{ item.compliance ? item.compliance : 0 }}%</span>
                      <span class="chevron-icon"><font-awesome-icon :icon="['fas', 'chevron-down']" /></span>
                    </b-button>
                  </b-card-header>

                  <b-collapse
                    v-if="item.regions && item.regions.length"
                    :class="{
                      '': !isDarkMode,
                      'bg-dark text-light': isDarkMode
                    }"
                    :id="getCollapseId(item.checklist, item.appendText)"
                    accordion="my-accordion"
                  >
                    <b-card
                      style="padding: 0; line-height: 0"
                      :class="{
                        '': !isDarkMode,
                        'bg-dark border text-light': isDarkMode
                      }"
                      v-for="(subItem, subIndex) in item.regions"
                      :key="subIndex"
                    >
                      <b-card-body style="padding: 0">
                        <p
                          >{{ subItem.region ? subItem.region : '' }}:
                          {{ subItem.compliance ? subItem.compliance : 0 }}%</p
                        >
                      </b-card-body>
                    </b-card>
                  </b-collapse>
                </b-card>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: {
    dashboardDetails: Object,
    loading: Boolean
  },
  computed: {
    ...mapState('darkMode', ['isDarkMode']),
    bars() {
      const defaultBars = Array.from({ length: 6 }, (_, i) => ({
        label: (i + 1) * 5
      }));
      return defaultBars.map((bar, index) => ({
        ...bar,
        ...(this.dashboardDetails.chartData[index] || {})
      }));
    }
  },
  methods: {
    getCollapseId(checklist, text) {
      return `${checklist.replace(/\s+/g, '-')}-${text}`; // Replace spaces with hyphens
    },
    trimText(text) {
      return text.length > 10 ? text.substring(0, 15) + '...' : text;
    }
  }
};
</script>

<style scoped>
@media (max-width: 1040px) {
  .dashHead {
    margin-top: 100px;
  }
}

::-webkit-scrollbar {
  width: 4px;
  /* Adjust width as needed */
  height: 4px;
}

/* Track (background) */

::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Light gray background */
}

/* Handle (the draggable part) */

::-webkit-scrollbar-thumb {
  background: #888;
  /* Darker gray scrollbar */

  border-radius: 5px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.bar {
  transition: height 0.3s ease-in-out;
}

.bordered {
  border: 1px solid #f1f1f1;
  border-radius: 10px;
  padding: 10px;
}

/* Main Accordion Card */
.accordion-card {
  border: none;
  box-shadow: none;
  background-color: none;
}

/* Accordion Header */
.accordion-header {
  padding: 0;
}

/* Toggle Button */
.accordion-toggle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px;
  font-size: 16px;
  font-weight: bold;
  background: none !important;
  border: none;
  text-align: left;
  box-shadow: none;
}

/* Checklist Title */
.checklist-title {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Completion Percentage */
.completion-percentage {
  font-size: 16px;
  font-weight: bold;
  margin-right: 10px;
}

/* Chevron Icon */
.chevron-icon {
  font-size: 14px;
  transition: transform 0.3s ease-in-out;
}

/* Rotate Chevron When Open */
.b-collapse:not(.show) .chevron-icon {
  transform: rotate(0deg);
}

.b-collapse.show .chevron-icon {
  transform: rotate(180deg);
}
</style>
